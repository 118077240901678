<template>
  <div class="main-div">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2" class="rowSpaceAround">
        <div class="organizationImg">
          <img src="@/assets/img/organizationNew.png" alt="">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
export default {
  name: "organization",
  components: { topPathList},
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标协会',

        },
        {
          name: '组织架构',
          path: '/giia/organization'
        },
      ],
    }
  }
}
</script>

<style scoped>
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .organizationImg {
    width: 1200px;
    height: 770px;
    box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
  }

  @media screen and (max-width: 1366px) {
    .main-div {
      background-color: #f8f9fa !important;
    }
    .breadTop {
      width: 1200px;
      margin: auto;
    }
    .organizationImg {
      width: 1200px;
      height: 770px;
      box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0px;
    }
  }
</style>
